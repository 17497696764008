import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Col, Row } from "reactstrap"
import Image from "gatsby-image"
import { Header, Works, Accordian } from "../components/leads"
import SEO from "../components/seo"

export default class Template extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      collapse: false,
    }
  }

  componentDidMount = () => {
    this.loadRedTrack()
    this.loadFraudBlocker()
  }

  loadFraudBlocker = () => {
    var s = document.createElement("script"),
      h = document.head
    s.async = 1
    s.src = "https://monitor.fraudblocker.com/fbt.js?sid=sVIntzCVLKWlaR_PgxcDy"
    h.appendChild(s)
  }

  loadBZ = (categoryID, clickId) => {
    const script = document.createElement("script")
    script.src = "https://cdn.buyerzone.com/apps/widget/bzWidget.min.js"
    script.async = true
    script.setAttribute("data-bzwidget", true)
    script.setAttribute("data-bzwidget-pub-id", "48078")
    script.setAttribute("data-bzwidget-color-palette-name", "default")
    script.setAttribute("data-bzwidget-category-id", categoryID)
    script.onload = () =>
      eval("bzWidget.init();bzWidget.initConversionEventListener();")
    document.getElementById("bzWidgetContainer").appendChild(script)
    document.getElementById(
      "bzWidgetContainer"
    ).dataset.bzwidgetKeywordId = clickId

    document.addEventListener(
      "bzConversion",
      function() {
        console.log("In  bzConversion")
        if (window.dataLayer) {
          console.log("In  bzConversion datalayer check")
          window.dataLayer.push({ event: "bzConversion" })
        }
      },
      false
    )
  }

  loadRedTrack = () => {
    let params = new URLSearchParams(window.location.search)
    const rtClickID = params.get("rtClickID")
    const {
      markdownRemark: { frontmatter: { categoryID } = {} } = {},
    } = this.props.data
    this.setCookie("rtkclickid-store", rtClickID)
    this.loadBZ(categoryID, rtClickID)
  }

  setCookie = (name, value) => {
    var expires = ""
    var date = new Date()
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
    document.cookie =
      name +
      "=" +
      (value || "") +
      expires +
      `; ${window.location.origin.split("://")[1]}` +
      "; path=/"
  }

  scrollandCollapse = collapse => {
    this.setState({
      ...this.state,
      collapse,
    })
  }

  render() {
    let frontmatter = {},
      html = "",
      image = ""
    if (this.props.data !== null && this.props.data.markdownRemark !== null) {
      frontmatter = this.props.data.markdownRemark.frontmatter
      image = this.props.data.markdownRemark.frontmatter.image.childImageSharp
      html = this.props.data.markdownRemark.html
    }
    const scrollDown = this.ref
    return (
      <Layout>
        <SEO title={frontmatter.categoryName} />
        <Header
          title={frontmatter.title}
          scrollDown={scrollDown}
          scrollandCollapse={this.scrollandCollapse}
        />
        <Row style={{ marginTop: -20 }} className="d-none d-lg-block mb-5">
          <Col xs={8} className="pt-3 pb-5 d-flex justify-content-center">
            <Image
              fluid={image.fluid}
              style={{ width: 400, marginLeft: -100 }}
            />
          </Col>
        </Row>
        <Works />
        <div ref={scrollDown}>
          <Accordian
            scrollandCollapse={this.scrollandCollapse}
            scrollCollapse={this.state.collapse}
            html={html}
            articleHeading={frontmatter.articleHeading}
          />
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($searchQuery: String!) {
    markdownRemark(frontmatter: { path: { eq: $searchQuery } }) {
      html
      frontmatter {
        categoryName
        title
        articleHeading
        categoryID
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
